import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL, fileUrl } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Components/Cookie/GetCookie";
import { ToastSuccess } from "../../../../Components/ToastAlert";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";

export default function CertificateEdit() {
  const { certificateId } = useParams();
  const [certificateCategory, setCertificateCategory] = useState([]);
  // Initial table data
  const [updateInfo, setUpdateInfo] = useState([]);

  const [pdfFile, setPdfFile] = useState();

  const name = useRef();
  const certificateFile = useRef();
  const certificateCategoryRef = useRef();
  const certificateRef = useRef();

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    console.log(e);
    setPdfFile(URL.createObjectURL(e.target.files[0]));

    // let allfiles = [];
    // for (let i = 0; i < e.target.files.length; i++) {
    //   allfiles.push(e.target.files[i]);
    // }
    // if (allfiles.length > 0) {
    //   setFile(allfiles);
    // }
  };

  // Get Single Info
  const renderUpdateInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/certificate/show/${certificateId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setUpdateInfo(res.data?.data);
        setPdfFile(`${fileUrl}/${res.data?.data?.certificate_file}`);
      });
  };

  // form submit to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PATCH");
    formdata.append("name", name.current.value);
    formdata.append("certificate_file", certificateFile.current.files[0]);
    formdata.append("cat_id", certificateCategoryRef.current.value);
    formdata.append("custom_id", certificateRef.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}admin/certificate/update/${certificateId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: "Update successful...!",
          });
          renderUpdateInfo();
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    const allCertificateCategory = async () => {
      await axios
        .get(`${BACKEND_BASE_URL}admin/certificate/category`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((res) => {
          setCertificateCategory(res.data?.data);
        });
    };
    allCertificateCategory();

    renderUpdateInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Update Certificate Category</div>
                  <Link to="/admin/certificate">
                    <Button
                      variant=""
                      size="sm"
                      style={{
                        backgroundColor: "#031b4e",
                        color: "#fff",
                      }}
                    >
                      View All
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={updatedData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add title */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Certificate Name&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="name"
                              defaultValue={updateInfo?.name}
                              ref={name}
                            />
                          </Form.Group>
                          {/* Category */}
                          <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label className="label fw-bold">
                              Certificate Category&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={certificateCategoryRef}
                            >
                              <option value="" selected>
                                Select Category
                              </option>
                              {certificateCategory?.map((data) => (
                                <option
                                  value={data?.id}
                                  key={data?.id}
                                  selected={
                                    data?.id == updateInfo?.cat_id
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* add Certificate file */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Certificate File&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              required
                              type="file"
                              accept="application/pdf"
                              placeholder="title"
                              ref={certificateFile}
                              defaultValue={updateInfo.certificate_file}
                              onChange={handleImgPreview}
                            />
                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            {files.length == 0 && (
                              <iframe
                                title="Certificate File"
                                src={pdfFile}
                                width="100%"
                                height="250px"
                                className="object-contain"
                              />
                            )}
                          </Form.Group>

                          {/* add certificate id */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Certificate Id&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="id"
                              ref={certificateRef}
                              defaultValue={updateInfo?.custom_id}

                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0 flex items-center justify-center"
                            style={{
                              backgroundColor: "#031b4e",
                              color: "white",
                            }}
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
