import React, { useRef, useState } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import axios from "axios";
import { BACKEND_BASE_URL, fileUrl } from "../Utils/GlobalVariables";
import { ToastSuccess } from "../Components/ToastAlert";

export default function Certificate() {
  const certificateRef = useRef();
  const [certificate, setCertificate] = useState("");
  const [status, setStatus] = useState("initial");
  const [errorMsg, setErrorMsg] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    setStatus("loading");
    axios
      .post(`${BACKEND_BASE_URL}frontend/search-certificate`, {
        custom_id: certificateRef.current.value,
      })
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: "Your message has been sent!",
          });
          setCertificate(response.data?.data);
          certificateRef.current.value = "";
          setErrorMsg("");
          setStatus("initial");
        } else {
          setErrorMsg(response.data?.message);
          certificateRef.current.value = "";
          setStatus("initial");
          setCertificate("");
        }
      });
  }
  console.log(certificate);
  return (
    <div>
      <Header />
      <div className="container py-40 ">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center items-center gap-2 mb-4">
            <input
              ref={certificateRef}
              type="text"
              placeholder="Certificate No."
              className="px-3 py-3 h-14 bg-grey-#f8f8f8 border border-grey-#686868 focus:outline-none placeholder:"
            />
            <button
              type="submit"
              disabled={status == "loading" ? "disabled" : ""}
              className="bg-black px-4 h-14 hover:bg-[#f78e09] text-white text-f18 disabled:bg-black/50"
            >
              {status == "loading" ? "Searching..." : "Search Certificate"}
            </button>
          </div>
        </form>
        <p className="flex justify-center">
          {certificate?.certificate_file && "Certificate found..."}
        </p>
        <p className="flex justify-center">{errorMsg && errorMsg}</p>

        {certificate && (
          <div className="flex justify-center mt-3">
            <a
              href={`${fileUrl}${certificate?.certificate_file}`}
              download
              className="px-4 py-2 border border-grey-999 "
            >
              Download
            </a>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
