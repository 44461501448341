import axios from "axios";
import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL, fileUrl } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Components/Cookie/GetCookie";
import Swal from "sweetalert2";
import { ToastSuccess } from "../../../../Components/ToastAlert";
import { Link } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

export default function AllCertificates() {
  // Initial table data
  const [tableData, setTableData] = useState([]);

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/certificate`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/certificate/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Manage Certificate </h5>
                  {/* <Link to="/admin/blogs">
                      <Button
                        variant=""
                        size="sm"
                        style={{ backgroundColor: "#031b4e", color: "white-#fff" }}
                      >
                        View All Blogs &nbsp;
                      </Button>
                    </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="card-header">
                  <Link to="/admin/certificate/create">
                    <button className="flex items-center bg-dark-blue-text text-white-#fff xl:px-5 xl:py-2">
                      Add New &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </button>
                  </Link>
                </div>

                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Certificate Category</th>
                        <th scope="col">Certificate ID</th>
                        <th scope="col">Certificate File</th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.name}</td>
                          <td>{data?.cat_info?.name}</td>
                          <td>{data?.custom_id}</td>
                          <td>
                            <iframe
                              title="Certificate file"
                              src={`${fileUrl}${data?.certificate_file}`}
                              width="100%"
                              height="350px"
                            />
                          </td>
                          <td className="text-center">
                            {/* edit button */}
                            <Link to={`/admin/certificate/edit/${data?.id}`}>
                              <button className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1">
                                <BiEdit
                                  style={{
                                    color: "#fff",
                                  }}
                                  title="Edit"
                                  size="1em"
                                />
                              </button>
                            </Link>

                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "#fff",
                                }}
                                title="Delete"
                                size="1em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
