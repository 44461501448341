import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link } from "react-router-dom";
import logo from "../assets/IISPL_logo_square.png";

export default function FloatingWhatsapp() {
  return (
    <>
    <FloatingWhatsApp
      phoneNumber="+8801787659307"
      accountName="IISPLBD"
      avatar={logo}
      
    />
    </>
  );
}
