import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BACKEND_BASE_URL } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Components/Cookie/GetCookie";
import { ToastSuccess } from "../../../../Components/ToastAlert";

const CertificateAdd = () => {
  const [certificateCategory, setCertificateCategory] = useState([]);
  const navigate = useNavigate();
  const name = useRef();
  const certificateFile = useRef();
  const certificateCategoryRef = useRef();
  const certificateRef = useRef();
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", name.current.value);
    formdata.append("certificate_file", certificateFile.current.files[0]);
    formdata.append("cat_id", certificateCategoryRef.current.value);
    formdata.append("custom_id", certificateRef.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin/certificate/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: "Certificate added successfully!",
          });
          e.target.reset();
          navigate("/admin/certificate");
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    const allCertificateCategory = async () => {
      await axios
        .get(`${BACKEND_BASE_URL}admin/certificate/category`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((res) => {
          setCertificateCategory(res.data?.data);
        });
    };
    allCertificateCategory();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Create Certificate</div>
                  <Link to="/admin/certificate">
                    <Button
                      variant=""
                      size="sm"
                      style={{
                        backgroundColor: "#031b4e",
                        color: "#fff",
                      }}
                    >
                      View All
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add certificate id */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Certificate Id&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="id"
                              ref={certificateRef}
                            />
                          </Form.Group>

                          {/* certificate category */}
                          <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label className="label fw-bold">
                              Certificate Category&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={certificateCategoryRef}
                            >
                              <option value="" selected>
                                Select Category
                              </option>
                              {certificateCategory?.map((data) => (
                                <option
                                  value={data?.id}
                                  key={data?.id}
                                  selected={
                                    data?.id == certificateCategory?.name
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* client name */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Client Name&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="name"
                              ref={name}
                            />
                          </Form.Group>

                          {/* add Certificate file */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Certificate&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="file"
                              accept="application/pdf"
                              placeholder="title"
                              ref={certificateFile}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0 flex items-center justify-center"
                            style={{
                              backgroundColor: "#031b4e",
                              color: "white",
                            }}
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CertificateAdd;
