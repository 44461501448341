import AdminContent from "./AdminContent";
import "./Admin.css";

const Admin = () => {
  return (
    <div className="position-relative">
      <AdminContent />
    </div>
  );
};

export default Admin;
